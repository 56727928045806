/* @media screen and (max-width: 991px) {
  .line {
    width: 100%;
    height: 300px;
    background-color: white;
  }

  .canvas-container {
    background-color: blue;
  }

} */

.line {
  width: 75%;
  height: 400px;
  background-color: white;
}

.canvas-container {
  background-color: blue;
}
