@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.login-container {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-image: url('../../util/loginHintergrund.jpg'); */
    background-size: cover;
    background-position: center;
}

/* Container */

.login-form {
    background: rgba(64, 64, 64, 0.15);
    border: 3px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
    border-radius: 16px;
    backdrop-filter: blur(25px);
    text-align: center;
    color: white;
    width: 625px;
    box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease, transform 0.3s ease; /* Füge einen Übergangseffekt hinzu */
    transform: translateY(0); 
}

.login-form:hover {
    box-shadow: 0 30px 50px 30px rgba(0, 0, 0, 0.175); /* Ändere den Schatten beim Schweben */
    transform: translateY(-5px); /* Hebe den Container leicht an */
}

.login-form::after {
    content: "";
    position: absolute;
    top: 100px; /* Positioniere die Linie unterhalb des Texts */
    left: 50%; /* Beginne die Linie in der horizontalen Mitte des .form-header */
    width: 300px; /* Breite der Linie */
    height: 1px; /* Dicke der Linie */
    background-color: #ccc; /* Farbe der Linie */
    transform: translateX(-50%); /* Zentriere die Linie horizontal */
}

.login-form2 {
    background: rgba(64, 64, 64, 0.15);
    border: 3px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
    border-radius: 16px;
    backdrop-filter: blur(25px);
    text-align: center;
    color: white;
    width: 625px;
    box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease, transform 0.3s ease; /* Füge einen Übergangseffekt hinzu */
    transform: translateY(0); 
}

.login-form2:hover {
    box-shadow: 0 30px 50px 30px rgba(0, 0, 0, 0.175); /* Ändere den Schatten beim Schweben */
    transform: translateY(-5px); /* Hebe den Container leicht an */
}

.login-form2::after {
    content: "";
    position: absolute;
    top: 100px; /* Positioniere die Linie unterhalb des Texts */
    left: 50%; /* Beginne die Linie in der horizontalen Mitte des .form-header */
    width: 300px; /* Breite der Linie */
    height: 1px; /* Dicke der Linie */
    background-color: #ccc; /* Farbe der Linie */
    transform: translateX(-50%); /* Zentriere die Linie horizontal */
}

.login-form3 {
    background: rgba(64, 64, 64, 0.15);
    border: 3px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
    border-radius: 16px;
    backdrop-filter: blur(25px);
    text-align: center;
    color: white;
    width: 625px;
    box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease, transform 0.3s ease; /* Füge einen Übergangseffekt hinzu */
    transform: translateY(0); 
}

.login-form3:hover {
    box-shadow: 0 30px 50px 30px rgba(0, 0, 0, 0.175); /* Ändere den Schatten beim Schweben */
    transform: translateY(-5px); /* Hebe den Container leicht an */
}

.login-form3::after {
    content: "";
    position: absolute;
    top: 100px; /* Positioniere die Linie unterhalb des Texts */
    left: 50%; /* Beginne die Linie in der horizontalen Mitte des .form-header */
    width: 300px; /* Breite der Linie */
    height: 1px; /* Dicke der Linie */
    background-color: #ccc; /* Farbe der Linie */
    transform: translateX(-50%); /* Zentriere die Linie horizontal */
}

/* Überschrift */

.login-title {
    font-size: 40px;
    margin-bottom: 60px;
    font-weight: bold;
}

/* Nutzername und Passwort Feld */

.login-input-box {
    margin: 20px 0;
    position: relative;
    display: flex;
    align-items: center;
}

.login-input-box input {
    flex: 1; /* Flexibles Wachstum des Inputs */
    background: rgba(255, 255, 255, 0.1);
    border: none;
    padding: 12px 12px 12px 45px;
    border-radius: 99px;
    outline: 3px solid transparent;
    transition: 0.3s;
    font-size: 17px;
    color: white;
    font-weight: 600;
}

.login-input-box button {
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    position: absolute;
    right: 10px; 
    top: 50%;
    transform: translateY(-50%);
}

.login-input-box i {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    color: rgba(255, 255, 255, 0.8);
}

.login-input-box.password button {
    right: unset;
    left: calc(100% - 50px); /* Positionierung relativ zum rechten Rand */
}

/* Passwort vergessen Feld */

.login-remember-password {
    display: flex;
    justify-content: right;
    margin: 20px 0;
    margin-top: -7.5px;
    font-size: 16px;
}

.login-remember-password label {
    display: flex;
    gap: 8px;
    cursor: pointer;
}

.login-remember-password input {
    accent-color: white;
    cursor: pointer;
}

.login-remember-password a {
    color: white;
    text-decoration: none;
}

.login-remember-password a:hover {
    text-decoration: underline;
}

/* Anmelden Button */

.login-btn {
    width: 100%;
    padding: 10px 0;
    margin-top: 10px;
    font-size: 1.3rem;
    font-weight: 500;
    color: #000;
    background-color: #eec346;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    height: 55px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 0 0 0.5px #000;
    transition: 0.3s;
}

.login-btn:hover {
    color: #000;
    background-color: rgb(196, 155, 34);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1), 0 0 0 0.5px #000; /* Erhöhen des Schattens beim Überfahren */}

/* SKM Unterleiste */

.login-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    color: #fff;
    padding: 12px;
    text-align: center;
}

/* Box bei falschen oder richtigen Anmeldedaten */

.login-error-message {
    position: absolute;
    bottom: calc(100% + 20px); /* 20px Abstand von der unteren Kante des vorherigen Elements */
    left: 0;
    width: 100%;
    color: #333;
    font-size: 20px;
    text-align: center;
    white-space: pre-line; /* Erlaube Zeilenumbrüche */
}

.login-success-message {
    position: absolute;
    bottom: calc(100% + 20px); /* 20px Abstand von der unteren Kante des vorherigen Elements */
    left: 0;
    width: 100%;
    color: #333;
    font-size: 20px;
    text-align: center;
}

/* Responsive Design für Smartphones im Hochformat */

@media only screen and (max-width: 576px) {
    .login-form {
        width: 90%; 
    }

    .login-form2 {
        width: 90%; 
    }

    .login-title {
        font-size: 34px;
    }

    .login-form::after {
        content: "";
        position: absolute;
        top: 90px; /* Positioniere die Linie unterhalb des Texts */
        left: 50%; /* Beginne die Linie in der horizontalen Mitte des .form-header */
        width: 200px; /* Breite der Linie */
        height: 1px; /* Dicke der Linie */
    }

    .login-form2::after {
        content: "";
        position: absolute;
        top: 90px; /* Positioniere die Linie unterhalb des Texts */
        left: 50%; /* Beginne die Linie in der horizontalen Mitte des .form-header */
        width: 200px; /* Breite der Linie */
        height: 1px; /* Dicke der Linie */
    }

    .login-form3::after {
        content: "";
        position: absolute;
        top: 90px; /* Positioniere die Linie unterhalb des Texts */
        left: 50%; /* Beginne die Linie in der horizontalen Mitte des .form-header */
        width: 200px; /* Breite der Linie */
        height: 1px; /* Dicke der Linie */
    }

    .login-error-message {
        font-size: 16px;
    }

    .login-btn {
        font-size: 20px;
        height: 50px;
    }

   
}

@media only screen and (max-width: 471px) {
    .login-form2::after {
        content: "";
        position: absolute;
        top: 135px; /* Positioniere die Linie unterhalb des Texts */
        left: 50%; /* Beginne die Linie in der horizontalen Mitte des .form-header */
        width: 200px; /* Breite der Linie */
        height: 1px; /* Dicke der Linie */
    }

    .login-form3::after {
        content: "";
        position: absolute;
        top: 135px; /* Positioniere die Linie unterhalb des Texts */
        left: 50%; /* Beginne die Linie in der horizontalen Mitte des .form-header */
        width: 200px; /* Breite der Linie */
        height: 1px; /* Dicke der Linie */
    }
}

@media only screen and (max-width: 376px) {
    .login-input-box {
        margin-left: -10px;
    }
}