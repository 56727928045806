/* ---------- FORM STYLING ---------- */
.body {
  font-family: 'Arial', sans-serif;
  /* background-image: url('../../util/loginHintergrund.jpg'); */
  background-color: #eec346;
  background-size: cover;
  background-position: center;
}

.wasgehtsiedasan {
  /* background-image: url('../../util/loginHintergrund.jpg'); */
  background-size: cover;
  background-position: center;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Ändern von 'flex-start' zu 'center' */
  align-items: center;
  /* Ändern von 'flex-start' zu 'center' */
  width: 100%;
  /* Ändern der Breite auf 100% */
  height: 100%;
  /* Ändern der Höhe auf 100% */
  min-height: 80vh;
  /* Mindesthöhe auf 100vh setzen */
  min-width: 80vw;
}

.form-container {
  margin-top: 190px;
  background-color: #fff;
  padding: 3.5rem;
  border-radius: 12px;
  box-shadow: 0 15px 30px 15px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  /* Füge einen Übergangseffekt hinzu */
  transform: translateY(0);
}

.form-container:hover {
  box-shadow: 0 30px 50px 30px rgba(0, 0, 0, 0.3);
  /* Ändere den Schatten beim Schweben */
  transform: translateY(-5px);
  /* Hebe den Container leicht an */
}

.form-container::before {
  content: '';
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  height: 20px;
  border-radius: 20px 20px 0 0;
  /* Runde die oberen Ecken ab */
}

.form-label {
  margin-left: 11px;
  font-weight: bold;
}

.form-control {
  width: 100%;
  padding: 0.8rem;
  font-size: 1rem;
  border: 2px solid #ced4da;
  border-radius: 10px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: auto;
  /* Zentriere die Textfelder und ändere den Abstand */
  margin-bottom: 1rem;
  margin-left: 10px;
}

.custom-alert {
  border-radius: 0.5rem;
  padding: 1rem;
  font-size: 20px;
  margin-bottom: 2.8rem;
  margin-top: -2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.custom-alert-gut {
  border-radius: 0.5rem;
  padding: 1rem;
  font-size: 20px;
  margin-bottom: 2.8rem;
  margin-top: -2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.custom-alert-gut :nth-child(1) {
  margin-top: 0.1rem;
  margin-right: 0.25rem;
}

.custom-alert button {
  margin-top: 1rem;
  /* Platzieren Sie den Button etwas unterhalb des Texts */
  margin-bottom: 0.5rem;
  /* Platzieren Sie den Button etwas unterhalb des Texts */
  font-size: 20px;
  width: 250px;
  border-radius: 20px;
}

.custom-alert :nth-child(1) {
  margin-top: 0.1rem;
  margin-right: -6.75rem;
}

.form-control:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control-feedback {
  margin-left: 0.75rem;
}

/* ---------- CHECKBOX STYLING ---------- */

.checkbox-label1 {
  margin-top: -0.5em;
  font-size: 16px;
}

.checkbox-label2 {
  margin-top: -0.5em;
  font-size: 16px;
}

.checkbox-label3 {
  margin-top: -0.5em;
  font-size: 16px;
}

.checkbox-label4 {
  margin-top: -0.5em;
  font-size: 16px;
}

.checkbox-label5 {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  margin-left: 0.75rem;
  font-size: 16px;
}

/* ---------- BUTTON STYLING ---------- */

/* Standard Bootstrap-Button-Stil */
.submit-button-beginnen,
.submit-button-beginnen:focus {
  width: 250px;
  padding: 0.8rem 1rem;
  font-size: 1.2rem;
  color: #000;
  background-color: #eec346;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  display: block;
  margin: -1.5rem auto 0;
  margin-bottom: -1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 0 0 0.5px #000;
}

.submit-button-beginnen:hover {
  color: #000;
  background-color: rgb(196, 155, 34);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1), 0 0 0 0.5px #000;
  /* Erhöhen des Schattens beim Überfahren */
}

.submit-button-beginnen2,
.submit-button-beginnen2:focus {
  width: 250px;
  padding: 0.8rem 1rem;
  font-size: 1.2rem;
  color: #000;
  background-color: #e75530;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  display: block;
  margin: -1.5rem auto 0;
  margin-left: -10rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 0 0 0.5px #000;
}

.submit-button-beginnen2:hover {
  color: #000;
  background-color: rgb(196, 34, 34);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1), 0 0 0 0.5px #000;
  /* Erhöhen des Schattens beim Überfahren */
}

.popup-button {
  border-radius: 30px;
}

.popup-button:hover {
  color: #ffffff;
  background-color: rgb(28, 93, 184);
}

/* ---------- HEADER STYLING ---------- */

.form-header {
  font-size: 34px;
  position: relative;
  /* Stelle sicher, dass das Pseudoelement relativ zum .form-header positioniert wird */
  margin-bottom: 2.5rem;
  margin-top: -0.5rem;
  color: #333;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
  text-align: center;
}

.form-header::after {
  content: "";
  position: absolute;
  top: 55px;
  /* Positioniere die Linie unterhalb des Texts */
  left: 50%;
  /* Beginne die Linie in der horizontalen Mitte des .form-header */
  width: 500px;
  /* Breite der Linie */
  height: 1px;
  /* Dicke der Linie */
  background-color: #ccc;
  /* Farbe der Linie */
  transform: translateX(-50%);
  /* Zentriere die Linie horizontal */
}

.form-header2 {
  font-size: 34px;
  position: relative;
  /* Stelle sicher, dass das Pseudoelement relativ zum .form-header positioniert wird */
  margin-bottom: 2.5rem;
  margin-top: -0.5rem;
  color: #333;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.form-header2::after {
  content: "";
  position: absolute;
  top: 55px;
  /* Positioniere die Linie unterhalb des Texts */
  left: 50%;
  /* Beginne die Linie in der horizontalen Mitte des .form-header */
  width: 500px;
  /* Breite der Linie */
  height: 1px;
  /* Dicke der Linie */
  background-color: #ccc;
  /* Farbe der Linie */
  transform: translateX(-50%);
  /* Zentriere die Linie horizontal */
}

/* Animation für das Ein- und Ausblenden */
@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }

  /* Startzustand */
  100% {
    opacity: 1;
    transform: scale(1);
  }

  /* Endzustand */
}

/* Medienabfragen für größere Bildschirme */
@media screen and (min-width: 768px) {
  .form-container {
    width: 60%;
    /* Verringere die Breite des Containers für größere Bildschirme */
  }
}

@media screen and (max-width: 993px) {

  .form-header::after {
    width: 400px;
  }

  .form-header2::after {
    width: 400px;
  }

  .form-wrapper {
    min-height: 70vh;
    /* Verringere die Mindesthöhe des Wrappers */
    min-width: 70vw;
    /* Verringere die Mindestbreite des Wrappers */
  }

  .form-container {
    width: 70%;
    /* Verringere die Breite des Containers */
    margin-top: 120px;
    /* Verringere den oberen Abstand des Containers */
  }

  .form-control {
    margin-left: 0;
    /* Setze den linken Rand auf 0 */
  }

  .form-label {
    margin-left: 0;
    /* Setze den linken Rand auf 0 */
  }

  .custom-alert,
  .custom-alert-gut {
    width: 80%;
    /* Ändere die Breite der benutzerdefinierten Warnungen */
    left: 50%;
    /* Zentriere die benutzerdefinierten Warnungen horizontal */
    transform: translateX(-50%);
    /* Zentriere die benutzerdefinierten Warnungen horizontal */
  }

  .custom-alert :nth-child(1),
  .custom-alert-gut :nth-child(1) {
    margin-right: 0;
    /* Setze den rechten Rand auf 0 */
  }

  .popup-button {
    width: 150px;
    /* Ändere die Breite des Popup-Buttons */
    font-size: 16px;
    /* Ändere die Schriftgröße des Popup-Buttons */
  }

  .form-control-feedback {
    margin-left: 0;
    /* Setze den linken Rand auf 0 */
    font-size: 12px;
    /* Ändere die Schriftgröße des Feedbacks */
  }

  .checkbox-label1 {
    margin-bottom: 10px;
    text-align: left;
  }

  .checkbox-label2 {
    margin-bottom: 10px;
    text-align: left;
  }


  .checkbox-label3 {
    margin-bottom: 10px;
    text-align: left;
  }

  .checkbox-label4 {
    margin-bottom: 10px;
    text-align: left;
  }

  .checkbox-label5 {
    margin-bottom: 32px;
    text-align: left;
  }

  .form-container {
    margin-top: 150px;
    width: calc(100% - 50px);
    /* Breite des Containers weniger breit */
    margin-top: 12rem;
    margin-bottom: 3rem;
    box-shadow: 0 15px 30px 15px rgba(0, 0, 0, 0.2);
  }
}


@media only screen and (max-width: 500px) {
  .form-container {
    margin-top: 150px;
    width: calc(100% - 50px);
    /* Breite des Containers weniger breit */
    margin-top: 12rem;
    margin-bottom: 3rem;
    box-shadow: 0 15px 30px 15px rgba(0, 0, 0, 0.2);
  }

  .form-control {
    display: flex;
    width: max-content;
    padding: 0.7rem;
    font-size: 0.9rem;
    margin-bottom: 0.7rem;
  }

  .form-label {
    font-weight: bold;
  }



  .submit-button-beginnen {
    padding: 0.65rem;
    font-size: 1.15rem;
    margin-bottom: -0.9rem;
  }

  .submit-button-beginnen:hover {
    color: #000;
    background-color: rgb(196, 155, 34);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1), 0 0 0 0.5px #000;
    /* Erhöhen des Schattens beim Überfahren */
  }

  .submit-button-beginnen:active {
    background-color: rgb(196, 155, 34);
  }

  .form-header {
    font-size: 26px;
  }

  .form-header2 {
    font-size: 26px;
  }

  .form-header::after {
    width: 250px;
    /* Breite der Linie auf 100px reduziert */
  }

  .form-header2::after {
    width: 250px;
    /* Breite der Linie auf 100px reduziert */
  }

  .checkbox-label1 {
    margin-bottom: 10px;
    text-align: left;
  }

  .checkbox-label2 {
    margin-bottom: 10px;
    text-align: left;
  }

  .checkbox-label3 {
    margin-bottom: 10px;
    text-align: left;
  }

  .checkbox-label4 {
    text-align: left;
  }

  .checkbox-label5 {
    margin-left: -28px;
    text-align: left;
  }

  .checkbox-input {
    margin-right: 10px;
    /* Füge Abstand zwischen dem Kästchen und dem Text hinzu */
  }

  .custom-alert {
    font-size: 18px;
    padding: 1.5rem;
    width: 310px;
    transform: translateX(-50%);
    left: 50%;
  }

  .custom-alert-gut {
    font-size: 18px;
    padding: 1.5rem;
    width: 310px;
    transform: translateX(-50%);
    left: 50%;
  }

  .custom-alert :nth-child(1) {
    margin-top: 0.25rem;
    margin-right: -7rem;
  }

  .custom-alert-gut :nth-child(1) {
    margin-top: 0.4rem;
    margin-right: 0.15rem;
  }

  .popup-button {
    width: 175px;
    font-size: 16px;
    padding: 0.8rem;
    margin-bottom: 0.5rem;
  }

  .form-control-feedback {
    /* margin-left: -3.25rem; */
    font-size: 12px;
  }
}

